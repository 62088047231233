<template>
  <v-card
    v-if="isUsable"
    v-bind:voucher="voucher"
    outlined
    class="d-flex flex-column rounded-md fill-height"
  >
    <v-img
      v-if="voucher.imageBase64"
      class="rounded-md rounded-bl-0 rounded-br-0"
      contain
      :src="handleImageSrc"
      alt=""
    ></v-img>
    <v-card-subtitle class="default--text text-center">
      Validità da
      <span class="font-weight-bold">{{ voucher.startRetreat }}</span>
      al
      <span class="font-weight-bold">{{ voucher.endRetreat }}</span>
    </v-card-subtitle>
    <v-card-text class="default--text" v-html="voucher.voucherPreviewHtml" />
    <v-spacer />
    <v-card-actions class="pa-4 justify-end">
      <!-- <v-btn
        v-if="isUsable"
        v-on:click.prevent="useVoucher(voucher.barCode)"
        color="primary"
        outlined
        depressed
      >
        {{ $t("dashboard.voucher.voucherCard.useVoucher") }}
      </v-btn> -->
      <v-btn
        :disabled="!isUsable"
        @click="dialog = true"
        color="primary"
        depressed
      >
        {{
          isUsable
            ? $t("dashboard.voucher.voucherCard.voucherCard")
            : $t("dashboard.voucher.voucherCard.voucherCardUsed")
        }}
        <v-dialog v-model="dialog" width="500" class="rounded-md">
          <v-card v-bind:voucher="voucher">
            <v-img
              v-if="voucher.imageBase64"
              contain
              :src="handleImageSrc"
              alt=""
            ></v-img>
            <v-card-subtitle class="pt-4 default--text text-center">
              Validità da
              <span class="font-weight-bold">{{ voucher.startRetreat }}</span>
              al
              <span class="font-weight-bold">{{ voucher.endRetreat }}</span>
            </v-card-subtitle>
            <v-card-text class="default--text" v-html="voucher.voucherHtml" />
            <v-card-actions class="voucher-close justify-end">
              <v-btn x-small fab @click="dialog = false">
                <v-icon>$close</v-icon>
              </v-btn>
            </v-card-actions>
            <barcode
              v-bind:value="voucher.barCode"
              class="barcode px-5 text-center"
            />
          </v-card>
        </v-dialog>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.text_04 {
  white-space: pre-wrap;
}
.v-dialog {
  &--active {
    position: relative;
    padding: 0;
    border-radius: 12px;
    .voucher-close {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .vue-barcode-element text {
    font: 14px "Montserrat", arial, sans-serif !important;
  }
}
</style>
<script>
import customService from "@/service/customService";
import VueBarcode from "vue-barcode";
export default {
  name: "VoucherCard",
  components: {
    barcode: VueBarcode
  },
  props: {
    voucher: {
      type: Object
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    isUsable() {
      return !this.voucher.redemmed && !this.voucher.booked;
    },
    handleImageSrc() {
      return (
        "data:image/jpg;base64," + this.voucher.imageBase64.trim() ||
        "/no-icon.png"
      );
    }
  },
  methods: {
    //TODO: use the usevoucher api call once ready
    async useVoucher(upc) {
      let result = await customService.useVoucher(upc);
      if (result) console.log(result);
    }
  }
};
</script>
