<template>
  <div>
    <CategoryTitle :category="category" />
    <div v-if="isVoucherPresent">
      <v-row>
        <v-col
          v-for="voucher in voucherList"
          :key="voucher.barCode"
          cols="12"
          sm="6"
          md="4"
        >
          <VoucherCard :voucher="voucher" />
        </v-col>
        <v-col cols="12">
          <v-pagination
            class="mt-12"
            color="primary"
            v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
            :value="pageFilter"
            :page="pager.selPage"
            :length="pager.totPages ? pager.totPages : 0"
            :totalVisible="6"
            @next.stop.prevent="handlePageFilter"
            @prev="handlePageFilter"
            @input="handlePageFilter"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog">
        <VoucherCard :voucher="selectedVoucher" />
      </v-dialog>
    </div>
    <div v-else class="mt-3">
      <p>Nessun buono trovato!</p>
    </div>
  </div>
</template>

<style lang="scss">
.v-card {
  .title {
    display: flex;
    flex-direction: column;
    > span {
      font-weight: normal;
      line-height: 1.2;
      margin-bottom: 4px;
      &.text_04 {
        font-weight: bold;
      }
    }
  }
  .barcode svg {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 375px) {
  .v-card .barcode svg {
    width: inherit;
    height: inherit;
  }
}
</style>

<script>
import login from "~/mixins/login";
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixin from "~/mixins/category";
import customService from "@/service/customService";
import { mapGetters } from "vuex";
import VoucherCard from "@/components/aliVoucher/VoucherCard.vue";

export default {
  name: "profileGift",
  components: { CategoryTitle, VoucherCard },
  data() {
    return {
      voucherList: [],
      selectedVoucher: null,
      dialog: false,
      pager: {}
    };
  },
  mixins: [login, categoryMixin],
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    }),
    isVoucherPresent() {
      return this.voucherList && this.voucherList.length > 0;
    }
  },

  methods: {
    async loadUserVouchers() {
      this.voucherList = await customService.getUserVouchers();
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchPage(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  created() {
    this.loadUserVouchers();
  }
};
</script>
